import axios, { AxiosError, AxiosResponse } from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const { response } = error;

    if (
      localStorage.getItem("authData") &&
      (response?.status === 401 ||
        (response?.status === 400 &&
          response?.data.message === "missing or malformed jwt"))
    ) {
      const user = JSON.parse(localStorage.getItem("authData") ?? "");
      localStorage.clear();

      if (user.isAdmin) {
        window.location.replace(user.isAdmin ? "/admin" : "/");
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
